<template>
  <div class="withdraw-tbt-block white--text px-md-6 px-4 py-3 ma-md-8 ma-4 d-flex justify-space-between align-center">
    <div>
      <div class="rem-md-4 rem-0">可領取 TBT</div>
      <div class="rem-md-28 rem-18 mx-md-5 mx-4">{{ parseInt(amount.toFixed(0)).toLocaleString() }} <span class="rem-md-7 rem-2">TBT</span></div>
    </div>
    <div class="withdraw-btn d-flex justify-center align-center can-click" @click="withdraw()">立即提領</div>
  </div>
</template>

<script>
import TBT from '@/plugins/tbt.js'
import TBTConsump from '@/plugins/tbtConsump.js'
export default {
  data(){
    return {
      tbtContract: null,
      tbtConsumpContract: null,
      amount: 0
    }
  },
  methods: {
    async withdraw(){
      this.tbtConsumpContract = await new TBTConsump()
      let result = await this.tbtConsumpContract.adminWithdrawTbt(this.amount)
      // console.log('result', result)
      if (result.txHash){
        this.$toasted.show(this.$t('txSend'))
      }else if (result.code === 4001){
        this.$toasted.error(this.$t('userRefuse'))
      }
    }
  },
  async mounted(){
    this.tbtContract = await new TBT()
    this.amount = await this.tbtContract.getBalance(this.$store.state.TBTConsumpAddress)
  }
}
</script>

<style lang="scss">
.withdraw-tbt-block{
  max-width: 100%;
  min-width: 513px;
  width: fit-content;
  border-radius: 10.73px;
  background: linear-gradient(270deg, #26A7C5 0%, #020D50 100%);
  @include dai_vuetify_md{
    width: 95%;
    min-width: 95%;
  }
  .withdraw-btn{
    width: 128.81px;
    height: 42.94px;
    border-radius: 80.5063px;
    background: linear-gradient(263.09deg, #FFFFFF -20.92%, #95EBFF 82.76%);
    font-size: 21.4684px;
    font-weight: 400;
    line-height: 25px;
    color: #021052;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, .2);
    @include dai_vuetify_md{
      width: 96px;
      height: 32px;
      font-size: 16px;
    }
  }
}
</style>